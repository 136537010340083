<template>
  <div class="collection-item">
    <div class="collection-image ">
      <a :href="getCollectionPath(collection.id)" class="block w-full flex rounded-t-lg">
        <div class="w-1/2">
          <img v-lazy="collection.preview_posts[0].cover_image_src" :alt="collection.title" class="" />
        </div>
        <div class="w-1/2 h-1/2 flex flex-wrap">
          <template v-for="index in 4" :key="index">
            <template v-if="collection.preview_posts[index]">
              <img 
                v-lazy="collection.preview_posts[index].cover_image_src" 
                :alt="collection.preview_posts[index].title" 
                class="w-1/2 h-full" 
              />
            </template>
            <template v-else>
              <div class="w-1/2 aspect-square bg-[#222222]"></div>
            </template>
          </template>
        </div>
      </a>
    </div>

    <div class="collection-info rounded-b-lg bg-grey-4 -mt-px p-2 py-2">
      <div class="collection-title flex justify-between items-center leading-6" :title="collection.title">
        <span>
          <template v-if="!collection.is_public">    
            <span class="text-12px text-grey-5"> 🔒 </span>
          </template>
          <a :href="getCollectionPath(collection.id)" class="hover:underline">{{ collection.title }}</a>
        </span>
        <div v-if='is_owner' 
             class='collection-more max-h-[24px] relative'
             ref="morePanel">
          <button 
            type="button"
            class='w-6 h-6 cursor-pointer more-trigger !p-0 rounded-full hover:bg-gray-100' 
            @click.stop="toggleMorePanel"
          >
            <span class='icon more-icon'></span> 
          </button>

          <div class='more-content absolute right-0'
               :class="{ '!block': isOpen, 'hidden': !isOpen }">
            <button class='w-full flex items-center' @click.prevent='editCollection(collection)'>
              <span class='icon edit-icon mr-2'></span>
              <span class='min-w-[32px]'>{{ $t('comment_form.edit') }}</span>
            </button>
            <button class='w-full flex items-center' @click.prevent='deleteCollection(collection)'>
              <span class='icon delete-icon mr-2'></span>
              <span class='min-w-[32px]'>{{ $t('comment_form.delete') }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="collection-posts leading-4">
        <span class="text-12px text-grey-5">{{ collection.total_posts }} models</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ['collection', 'is_owner'],
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleMorePanel() {
      this.isOpen = !this.isOpen;
    },

    handleClickOutside(event) {
      if (!this.isOpen) return;

      const morePanel = this.$refs.morePanel;
      if (morePanel && !morePanel.contains(event.target)) {
        this.isOpen = false;
      }
    },

    getCollectionPath(collectionId) {
      return `/p/${window.location.pathname.split('/')[2]}/collections/${collectionId}`;
    },

    editCollection(collection) {
      const username = window.location.pathname.split('/')[2];
      window.location.href = `/p/${username}/collections/${collection.id}/edit`; // 绝对路径
    },

    async deleteCollection(collection) {
      if (!confirm(this.$t('common.confirm_delete'))) return;

      if (!this.is_owner) {
        return;
      }

      try {
        const username = window.location.pathname.split('/')[2];
        const response = await fetch(`/p/${username}/collections/${collection.id}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
            'Accept': 'application/json'
          }
        });

        if (response.ok) {
          this.$emit('collection-deleted', collection.id);
        }  else {
          const data = await response.json();
          window.alert(data.error);
        }
      } catch (error) {
        window.alert(error);
      }
    },
  },
})
</script>

