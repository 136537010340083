<template>
	<div class="w-full flex flex-col flex-wrap"> 
    <div v-if="hasCollections && state.isOwner"
         class="flex flex-col sm:flex-row justify-between items-start sm:items-center pb-1 mb-2">
      <div class="review-search-form flex flex-col lg:flex-row">
        <div class="flex flex-1 flex-col lg:flex-row w-full lg:w-2/3 lg:mb-0 justify-between items-center">
          <div class="flex flex-col w-full justify-between sm:flex-row">
            <div class='comment-order-type pr-2'> 
              <input type="radio" 
                      name='comment-order-type' 
                      class='hidden' 
                      id='order_type_latest' 
                      value="latest" 
                      :checked="state.visibilityFilter == 'all'">
              <label class='text-grey-3' for='order_type_latest' @click="updateVisibilityFilter('all')">
                {{ $t('post_index_collection.all') }} ({{ state.collections.length }})
              </label>
              <span class='text-grey-3'>|</span>
              <input type="radio" 
                      name='comment-order-type' 
                      class='hidden' 
                      id='order_type_positive'
                      value="public" 
                      :checked="state.visibilityFilter == 'public'">
              <label class='text-grey-3' for='order_type_positive' @click="updateVisibilityFilter('public')">
                {{ $t('models.base_form.enabled.enabled_true') }} ({{ state.collections.filter(collection => collection.is_public).length }})
              </label>
              <span class='text-grey-3'>|</span>
              <input type="radio" 
                      name='comment-order-type' 
                      class='hidden' 
                      id='order_type_negative' 
                      value="private" 
                      :checked="state.visibilityFilter == 'private'">
              <label class='text-grey-3' for='order_type_negative' @click="updateVisibilityFilter('private')">
                {{ $t('models.base_form.enabled.enabled_false') }} ({{ state.collections.filter(collection => !collection.is_public).length }})
              </label>
            </div>
          </div>
        </div>
      </div>
      <a  href="./collections/new"  
          class="w-full sm:w-auto mt-2 sm:mt-0 flex items-center justify-center text-14px btn btn-secondary py-1 rounded-[20px]">
        <div class="w-3 h-3 brightness-150 transform -rotate-45 icon close mr-2"></div>
        {{ $t('post_index_collection.new_collection') }}
      </a>
    </div>
		<div id="designer-post-collections" class="flex-1">
      <template v-if="hasCollections">
        <designer-collection-item 
          v-for="collection in visibleCollections" 
          :key="collection.id" 
          :collection="collection"
          :is_owner="state.isOwner"
          @collection-deleted="handleCollectionDeleted">
        </designer-collection-item>
      </template>
      <template v-else-if="state.loading">
        <div class="text-center post-item-loading mx-auto">
          {{ $t('post_index_collection.loading') }}
        </div>
      </template>
      <div v-else class="flex flex-col items-center justify-center mt-[128px] mx-auto">
        <div class="w-12 h-12 icon archive lg:mt-[176px]"></div>
        <div class="text-16px my-6">{{ $t('post_index_collection.no_collection_list') }}</div>
        <a  v-if="state.isOwner"
            href="collections/new"  
            class="flex items-center justify-center text-14px btn btn-secondary rounded-[20px]">
          <div class="w-3 h-3 brightness-150 transform -rotate-45 icon close mr-2"></div>
          {{ $t('post_index_collection.new_collection') }}
        </a>
      </div>
		</div>
	</div>
</template>
  
<script>

import { defineComponent, reactive, computed, onMounted, onUnmounted } from "vue";

export default defineComponent({
  name: 'DesignerCollectionIndex',
  
  setup() {
    const state = reactive({
      visibilityFilter: 'all',
      collections: [],
      currentPage: 1,
      perPage: 20,
      totalPages: 0,
      loading: false,
      hasMore: true,
      username: '',
      totalEntries: 0,
      isOwner: false
    });

    const hasCollections = computed(() => state.collections.length > 0);
    const visibleCollections = computed(() => {
      switch(state.visibilityFilter) {
        case 'public':
          return state.collections.filter(collection => collection.is_public);
        case 'private':
          return state.collections.filter(collection => !collection.is_public);
        default:
          return state.collections;
      }
    });

    const getUsername = () => {
      const url = window.location.pathname;
      const match = url.match(/\/p\/([^\/]+)/);
      return match ? match[1] : '';
    };

    const fetchCollections = async (page = 1) => {
      if (state.loading || !state.hasMore) return;

      state.loading = true;
      const url = `/api/v1/profiles/${state.username}/get_designer_collections`;
      
      const params = new URLSearchParams({
        mode: 'full',
        page: page,
        per_page: state.perPage
      });

      try {
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          const data = await response.json();

          if (page === 1) {
            state.collections = data.data;
          } else {
            state.collections.push(...data.data);
          }
          
          state.totalPages = data.total_pages;
          state.currentPage = data.current_page;
          state.totalEntries = data.total_entries;
          state.isOwner = data.is_owner;
          state.hasMore = state.currentPage < state.totalPages;
        }
      } catch (error) {
        console.error('Error fetching collections:', error);
      } finally {
        state.loading = false;
      }
    };

    const handleScroll = () => {
      if (!state.hasMore || state.loading) return;

      const scrollableHeight = document.body.scrollHeight - window.innerHeight - 150;
      const scrolledDistance = Math.max(
				window.pageYOffset,
				document.documentElement.scrollTop,
				document.body.scrollTop
			);

			if (scrolledDistance >= scrollableHeight) {
				if (state.loading == false) {
          fetchCollections(state.currentPage + 1);
				}
			}
    };

    const throttledScroll = (fn, delay = 200) => {
      let lastCall = 0;
      return function (...args) {
        const now = Date.now();
        if (now - lastCall >= delay) {
          fn.apply(this, args);
          lastCall = now;
        }
      };
    };

    const updateVisibilityFilter = (filterType) => {
      if (state.visibilityFilter === filterType) return;
      state.visibilityFilter = filterType;
    };

    const handleCollectionDeleted = (collectionId) => {
      state.collections = state.collections.filter(collection => collection.id !== collectionId);
      document.querySelector('.collections-count').textContent = `(${state.collections.length})`;
    };
    

    onMounted(() => {
      state.username = getUsername();
      fetchCollections();

      const throttledHandler = throttledScroll(handleScroll);

      if (window.isFirefox()) {
        window.addEventListener('scroll', throttledHandler, true);
      } else {
        window.addEventListener('scroll', throttledHandler);
      }
    });

    onUnmounted(() => {
      const throttledHandler = throttledScroll(handleScroll);
      
      if (window.isFirefox()) {
        window.removeEventListener('scroll', throttledHandler, true);
      } else {
        window.removeEventListener('scroll', throttledHandler);
      }
    });

    return {
      state,
      hasCollections,
      visibleCollections,
      fetchCollections,
      updateVisibilityFilter,
      handleCollectionDeleted
    };
  }
});
</script>
  