import { Controller } from "stimulus"
import Swiper, { Autoplay, EffectCoverflow } from 'swiper';

// 用在首頁的image slide
export default class extends Controller {
  static targets = ['swiper']

  connect() {
    this.designer_swiper = new Swiper(this.swiperTarget, {
      modules: [ Autoplay, EffectCoverflow ],
      loop: true,
      effect: 'coverflow',
      slidesPerView: 2,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 300,
        modifier: 2,
        slideShadows: true
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      }
    });
  }
  disconnect() {
    this.designer_swiper.destroy()
    this.designer_swiper = null
  }
}
