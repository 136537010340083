<template>
  <div class="flex items-center my-4">
    <a href="../collections" class="text-12px">{{ $t('post_index_collection.collections') }}</a>
      <img src="../../../../../images/breadcrumb_arrow.svg" class="inline-block mx-2 w-4 h-4" alt="Breadcrumb Arrow" />
      <span class="text-12px text-grey-5">{{ $t('post_index_collection.create_new_collection') }}</span>
  </div>
	<base-collection-form 
	  mode="new"
	  :initial-data="{
		title: '',
		is_public: true,
		selectedItems: []
	  }"
	/>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
	name: 'NewCollection',
  });
  </script>