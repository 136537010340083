<template>
  <div v-if="items.length > 0" id="profile-collection">
    <div id="profile-item-title">✨ <span>{{ $t('profile_collection.recommend') }}</span> ✨</div>
    <div id="profile-item-collection" class="flex">
      <div v-for="profile in items" :key="profile.id">
        <div class="profile-item">
          <a
            :href="profile.public_url"
            class="block rounded-full overflow-hidden"
          >
            <img
              v-lazy="profile.avatar_url"
              :alt="profile.brand_name"
              class="image"
            />
          </a>
          <div class="profile-name w-full py-2">
            <div
              class="text-grey-5 text-14px truncate font-medium"
              :title="profile.brand_name"
            >
              {{ profile.brand_name }}
            </div>
          </div>
          <template v-if="profile.followable != null">
            <div
              v-if="profile.followable"
              class="follow-btn follow"
              @click="followBtnClick(profile)"
            >
              {{ $t('profile_collection.follow') }}
            </div>
            <div
              v-else
              class="follow-btn unfollow"
              @click="unfollowBtnClick(profile)"
            >
              {{ $t('profile_collection.following') }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const searchPath = "/api/v1/posts/recommand_creators";

export default {
  data() {
    return {
      itemsCols: 8,
      itemsRows: 1,
      itemsCount: 8,
      items_not_found: false,
      items_data: [],
      items: [],
    };
  },
  mounted() {
    this.setupQueryString();
    this.setupItemColsCount();
    this.fetchItemData();
    window.addEventListener("resize", () => {
      this.setupItemColsCount();
      this.items = [];
      this.items.push(...this.items_data.slice(0, this.itemsCount));
    });
  },
  methods: {
    followBtnClick(profile) {
      this.followCreator(profile);
    },
    unfollowBtnClick(profile) {
      this.unfollowCreator(profile);
    },
    async followCreator(profile) {
      try {
        const url = "/api/v1/profiles/" + profile.username + "/follow";
        const response = await fetch(url, { method: "POST" });

        if (response.ok) {
          const data = await response.json();

          if (data.success) {
            const profileIndex = this.items.indexOf(profile);
            this.items[profileIndex].followable = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async unfollowCreator(profile) {
      try {
        const url = "/api/v1/profiles/" + profile.username + "/unfollow"
        const response = await fetch(url, {method: 'DELETE'})

        if (response.ok) {
          const data = await response.json();

          if (data.success) {
            const profileIndex = this.items.indexOf(profile)
            this.items[profileIndex].followable = true
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchItemData() {
      try {
        let curr_params = {...this.current_params}
        // per_page 最低數量為4
        curr_params['per_page'] = 8

        const response = await fetch(searchPath + '?' + new URLSearchParams(curr_params));
        if (response.ok) {
          const data = await response.json();
          const itemsData = data['data']

          this.items = []
          this.items_data = []

          if (itemsData.length == 0) {
            this.items_not_found = true
          } else {
            this.items.push(...itemsData.slice(0, this.itemsCount));
            this.items_data.push(...itemsData);
          }
        }
      } catch (error) {
        this.items = [];
      }
    },
    setupQueryString() {
      const currParams = new URLSearchParams(window.location.search);
      const paramsObject = Object.fromEntries(currParams);

      this.current_params = paramsObject
    },
    setupItemColsCount() {
      if (window.innerWidth >= 1536) {
        this.itemsCols = 8
      }  else if (window.innerWidth >= 780) {
        this.itemsCols = 6
      } else if (window.innerWidth >= 705) {
        this.itemsCols = 5
      } else {
        this.itemsCols = 4
      }

      this.itemsCount = this.itemsCols * this.itemsRows
    }
  }
};
</script>
