import * as Sentry from "@sentry/browser";
import GtmEvent from "components/gtm_event";
import FbPixelEvent from "components/fb_pixel_event";
import AmplitudeEvent from "components/amplitude_event";

class TrackingEvent {
  constructor(dataLayer, fbq, amplitude) {
    this.gtmEvent = null
    this.fbEvent = null
    this.amplitudeEvent = null

    // gtm 會拉取 dataLayer 的資料
    if(dataLayer) {
      this.gtmEvent = new GtmEvent(dataLayer)
    } else {
      console.error('GTM not found');
      Sentry.captureException('GTM not found');
    }

    // fb
    if(fbq) {
      this.fbEvent = new FbPixelEvent(fbq)
    } else {
      console.error('FB not found');
      Sentry.captureException('FB not found');
    }

    // amplitude
    if(amplitude) {
      this.amplitudeEvent = new AmplitudeEvent(amplitude)
    } else {
      console.error('Amplitude not found');
      Sentry.captureException('Amplitude not found');
    }
  }
  // ga4 ecommerce event list

  // normal
  // 1. view_item         - postEventInfo 
  // 2. add_to_cart       - postEventInfo
  // 3. remove_from_cart  - postEventInfo
  // 4. add_to_wishlist   - postEventInfo
  
  // checkout start
  // 5. view_cart         - cartEventInfo
  // 6. begin_checkout    
  // 7. add_shipping_info
  // 8. add_payment_info
  // 9. purchase          - puchaseInfo

  // 10. refund
  // 11. view_promotion   - promotionEvent
  // 12. select_promotion - promotionEvent 
  // 13. view_item_list
  // 14. select_item      

  // FB
  // normal
  // 1. ViewContent         - postEventInfo
  // 2. AddToCart
  // 3. AddToWishlist
  // 4. CompleteRegistration
  // 5. InitiateCheckout
  // 6. Purchase


  // post event
  view_item(postData) {
    try{
      if(postData) {
        if(this.gtmEvent) this.gtmEvent.postEvent('view_item', postData)
        if(this.fbEvent) this.fbEvent.postEvent('ViewContent', postData)
        if(this.amplitudeEvent) this.amplitudeEvent.viewPost(postData)
      }
    } catch(err) {
      Sentry.captureException(`view_item error: ${err}`);
    }
  }

  // cart
  add_to_cart(postData) {
    try{
      if(postData) {
        if(this.gtmEvent) this.gtmEvent.postEvent('add_to_cart', postData.post)
        if(this.fbEvent) this.fbEvent.postEvent('AddToCart', postData)
        if(this.amplitudeEvent) this.amplitudeEvent.addToCart(postData)
      }
    } catch(err) {
      Sentry.captureException(`add_to_cart error: ${err}`);
    }
  }

  remove_from_cart(postData) {
    try{
      if(postData) {
        if(this.gtmEvent) this.gtmEvent.postEvent('remove_from_cart', postData)
      }
    } catch(err) {
      Sentry.captureException(`remove_from_cart error: ${err}`);
    }
  }

  // cart event
  view_cart(cartData) {
    try{
      if(cartData) {
        if(this.gtmEvent) this.gtmEvent.cartEvent('view_cart', cartData)
      }
    } catch(err) {
      Sentry.captureException(`view_cart error: ${err}`);
    }
  }

  // download
  post_download(downloadData) {
    try{
      if(downloadData) {
        if(this.gtmEvent) this.gtmEvent.trackDownload('post_download', downloadData)
      }
    } catch(err) {
      Sentry.captureException(`post_download error: ${err}`);
    }
  }

  share(shareData) {
    try{
      if(shareData) {
        if(this.gtmEvent) this.gtmEvent.share(shareData)
      }
    } catch(err) {
      Sentry.captureException(`share error: ${err}`);
    }
  }

  // use backend api
  // sign_up(userData) {}

  // promotion
  view_promotion(promotionData, cartData) {
    try{
      if(cartData) {
        if(this.gtmEvent) this.gtmEvent.promotionEvent('view_promotion', promotionData, cartData)
      }
    } catch(err) {
      Sentry.captureException(`view_promotion error: ${err}`);
    }
  }

  select_promotion(promotionData, cartData) {
    try{
      if(cartData) {
        if(this.gtmEvent) this.gtmEvent.promotionEvent('select_promotion', promotionData, cartData)
      }
    } catch(err) {
      Sentry.captureException(`select_promotion error: ${err}`);
    }
  }

  // purchase
  purchase(orderData) {
    try{
      if(orderData) {
        if(this.gtmEvent) this.gtmEvent.purchase(orderData)
        if(this.fbEvent) this.fbEvent.purchase(orderData)
        if(this.amplitudeEvent) this.amplitudeEvent.purchase(orderData)
      }
    } catch(err) {
      Sentry.captureException(`purchase error: ${err}`);
    }
  }

  user_signup(userData) {
    try{
      if (userData) {
        if(this.amplitudeEvent) this.amplitudeEvent.userSignup(userData)
      }
    } catch(err) {
      Sentry.captureException(`user signup error: ${err}`);
    }
  }

  user_login(userData) {
    try{
      if (userData) {
        if(this.amplitudeEvent) this.amplitudeEvent.userLogin(userData)
      }
    } catch(err) {
      Sentry.captureException(`user login error: ${err}`);
    }
  }

  user_logout() {
    try{
      if(this.amplitudeEvent) this.amplitudeEvent.userLogout()
    } catch(err) {
      Sentry.captureException(`user logout error: ${err}`);
    }
  }

  set_allow_consent() {
    try{
      if(this.gtmEvent) this.gtmEvent.setConsent('consent', 'update', { 
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
      })
      if(this.fbEvent) this.fbEvent.setConsent('grant')
    } catch(err) {
      Sentry.captureException(`set consent error: ${err}`);
    }
  }

  set_deny_consent() {
    try{
      if(this.gtmEvent) this.gtmEvent.setConsent('consent', 'update', { 
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
      })
      if(this.fbEvent) this.fbEvent.setConsent('revoke')
    } catch(err) {
      Sentry.captureException(`set consent error: ${err}`);
    }
  }
}
export const initTrackingEvent = () => {
  window.trackingEvent = new TrackingEvent(window.dataLayer, window.fbq, window.amplitude)
}