<template>
	<div class="designer-collection-form">
	  <form @submit.prevent="handleSubmit" class="mx-4">
		<!-- Collection Name -->
		<div class="my-4">
		  <label class="block text-16px mb-2">
			{{ $t('post_index_collection.collection_name') }}
			<span class="text-orange-20 align-text-top md:inline-block">*</span>
		  </label>
		  <div class="relative" :class="{ 'error': hasError('title') }">
			<input 
			  type="text"
			  v-model="state.formData.title"
			  :maxlength="maxLength"
			  class="w-ful default-input-klass px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-10"
			  @input="validateField('title')"
			  @blur="validateField('title')"
			/>
			<p v-if="hasError('title')" class="absolute -bottom-5 left-0 text-red-500 text-xs">
			  {{ state.errors.title }}
			</p>
		  </div>
		</div>

		<!-- Privacy Setting -->
		<div class="mb-2">
		  <div class="radio-group">
        <div class="radio-item" @click="setPrivacy(true)">
          <input 
          type="radio" 
          id="public-option"
          name="privacy" 
          value="true"
          v-model="state.formData.is_public"
          />
          <label for="public-option">{{ $t('models.base_form.enabled.enabled_true') }}</label>
          <div class="check"><div class="inside"></div></div>
        </div>
        <div class="radio-item" @click="setPrivacy(false)">
          <input 
          type="radio" 
          id="private-option"
          name="privacy" 
          value="false"
          v-model="state.formData.is_public"
          />
          <label for="private-option">{{ $t('models.base_form.enabled.enabled_false') }}</label>
          <div class="check"><div class="inside"></div></div>
        </div>
		  </div>
		</div>

		<!-- Post Selection -->
		<div>
		  <label class="block">
			  {{ $t('post_index_collection.choose_models') }}
			  <span class="text-orange-20 align-text-top md:inline-block">*</span>
		  </label>

		  <div class="flex-1">
        <div class="review-search-form flex flex-col lg:flex-row">
          <div class="flex flex-1 flex-col lg:flex-row w-full lg:w-2/3 lg:mb-0 justify-between items-center">
            <div class="flex flex-col w-full">
              <div class="relative mt-4 flex flex-wrap items-center justify-between">
                <div class="search-icon" @click="performSearch"></div>
                <input 
                  v-model="state.currentSearch" 
                  type="search" 
                  :placeholder="$t('models.model_search_form.search')" 
                  class="w-full sm:w-auto flex review-search-bar w-full h-10 bg-grey-4 py-2 px-4 rounded-3xl sm:w-[292px] border border-transparent focus:outline-none focus:border-[#2bdee9] placeholder:text-[16px]"
                  @keydown.enter.prevent.stop="handleSearchKeydown"
                  @search="performSearch"
                >
                <div class="text-14px text-grey-5 mt-2">
                  {{ $t('post_index_collection.selected') }} ({{ state.formData.selectedItems.length }}/{{ displayItems.length }})
                </div>  
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 text-14px">  
          <!-- Loading State -->
          <div v-if="state.loading"
              class="flex flex-col items-center justify-center aspect-[304/610] sm:aspect-[672/544] md:aspect-[536/442] lg:aspect-[828/468] 2xl:aspect-[1024/424]">
            <p class="text-16px font-medium text-center">
              {{ $t('models.index.loading') }}
            </p>
          </div>

          <!-- Empty State -->
          <div v-else-if="showEmptyState"
              class="flex flex-col items-center justify-center aspect-[304/610] sm:aspect-[672/544] md:aspect-[536/442] lg:aspect-[828/468] 2xl:aspect-[1024/424]">
            <img src="../../../../../images/sad.svg" class="block w-12 h-12 mb-4" />
            <p class="text-16px font-medium text-center">
              {{ $t('campaigns.index.not_found') }} 
            </p>
          </div>  

          <!-- Content State -->
          <div v-else
            class="aspect-[304/610] sm:aspect-[672/544] md:aspect-[536/442] lg:aspect-[828/468] 2xl:aspect-[1024/424] model-container max-h-full h-full overflow-auto scrollbar pr-1 -mr-3">
            <sorted-model-item
              v-for="(post, idx) in displayItems" 
              source="BaseCollectionForm"
              class="sorted-model-item"
              :key="post.id"
              :class="{ 'selected': isSelected(post.id) }"
              :data-model-id="post.id"
              :post="post"
              :index="idx + 1"
              :order-type="''"
              @click="toggleSelect(post.id)"
            />
          </div>
        </div>
		  </div>
		</div>

		<!-- Form Actions -->
		<div v-if="!state.loading" 
         class="flex justify-center items-center space-x-4 mt-4">
		  <button 
			type="button"
			class="btn btn-secondary"
			@click="handleCancel"
		  >
			{{ $t('models.index.cancel') }}
		  </button>
		  <button 
			type="submit"
			class="btn btn-primary"
			:disabled="!isFormValid || state.isSaving"
		  >
			{{ state.isSaving 
				? (mode === 'new' ? 'Creating' : 'Updating')
				: (mode === 'new' ? $t('post_index_collection.create') : $t('post_index_collection.update')) 
			}}
		  </button>
		</div>
	  </form>
	</div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted } from 'vue';

export default defineComponent({
	name: 'BaseCollectionForm', 
	props: {
	  mode: {
      type: String,
      required: true,
      validator: value => ['new', 'edit'].includes(value)
	  },
	  initialData: {
      type: Object,
      required: true
	  }
	},

  setup(props) {
    const state = reactive({
      currentSearch: '',
      loading: false,
      isSearched: false,
      isSaving: false,
      items: [],
      filteredItems: [],
      formData: {
        title: props.initialData.title,
        is_public: props.initialData.is_public,
        selectedItems: props.initialData.selectedItems || []
      },
      errors: {
        title: ''
      }
    });

    const maxLength = 100;

    const isFormValid = computed(() => {
      return state.formData.title.trim() && state.formData.selectedItems.length > 0;
    });
      
    const showEmptyState = computed(() => {
      return !state.loading && (
        (state.currentSearch && state.isSearched && state.filteredItems.length === 0) ||
        (!state.currentSearch && state.items.length === 0)
      );
    });

    const displayItems = computed(() => {
      const items = state.filteredItems.length > 0 ? state.filteredItems : state.items;
      return items.filter(post => post.state === 'published' && post.visible);
    });

    const validateField = (fieldName) => {
      state.errors[fieldName] = '';

      if (fieldName === 'title') {
        if (!state.formData.title.trim()) {
          state.errors.title = 'Please enter the collection name';
          return false;
        }
        if (state.formData.title.length >= maxLength) {
          state.errors.title = `Name cannot exceed ${maxLength} characters`;
          return false;
        }
      }
      return true;
    };

    const validateForm = () => {
      let isValid = true;
      isValid = validateField('title') && isValid;
      return isValid && state.formData.selectedItems.length > 0;
    };

    const hasError = (fieldName) => {
      return !!state.errors[fieldName];
    };

    const fetchItems = async () => {
      state.loading = true;
      const url = `/api/v1/profiles/all_models`;
      const params = new URLSearchParams({
        kw: state.currentSearch,
      });

      try {
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          state.items = await response.json();
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
      }
      finally {
        state.loading = false;
      }
    };

    const performSearch = () => {
      if (!state.currentSearch.trim()) {
        state.filteredItems = [];
        state.isSearched = false;
        return;
    }

    const searchTerm = state.currentSearch.toLowerCase();
      state.filteredItems = state.items.filter(item => 
        item.title.toLowerCase().includes(searchTerm)
      );
      state.isSearched = true;
    };

    const handleSearchKeydown = (event) => {
      if (event.key === 'Enter') {
        performSearch();
      }
    };

    const toggleSelect = (itemId) => {
      const index = state.formData.selectedItems.indexOf(itemId);
      if (index === -1) {
        state.formData.selectedItems.push(itemId);
      } else {
        state.formData.selectedItems.splice(index, 1);
      }
    };

    const isSelected = (itemId) => {
      return state.formData.selectedItems.includes(itemId);
    };

    // 表單提交
    const handleSubmit = async () => {
      if (!validateForm()) return;

      state.isSaving = true;
      try {
        const username = window.location.pathname.split('/')[2];
        const url = props.mode === 'new' 
        ? `/p/${username}/collections`
        : `/p/${username}/collections/${props.initialData.id}`;

        const response = await fetch(url, {
        method: props.mode === 'new' ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          designer_post_collection: {
          title: state.formData.title,
          is_public: state.formData.is_public,
          post_ids: state.formData.selectedItems
          }
        })
        });

        const data = await response.json();
        if (response.ok) {
        handleCancel();
        } else {
        window.alert(data.errors);
        handleCancel();
        }
      } catch (error) {
        window.alert(`Failed to ${props.mode} collection: ${error}`);
        handleCancel();
      } finally {
        state.isSaving = false;
      }
    };

    const handleCancel = () => {
      const username = window.location.pathname.split('/')[2];
      window.location.href = `/p/${username}/collections`;
    };

    const setPrivacy = (value) => {
      state.formData.is_public = value;
    };

    onMounted(async () => {
      try {
        await fetchItems();     
      } catch (error) {
        console.error('Error in onMounted:', error);
      }
    });

    return {
      state,
      maxLength,
      isFormValid,
      displayItems,
      showEmptyState,
      hasError,
      validateField,
      handleSubmit,
      handleCancel,
      performSearch,
      handleSearchKeydown,
      toggleSelect,
      isSelected,
      setPrivacy
    };
  }
});
</script>
