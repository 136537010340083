<template>
  <div class="post-item">
    <div class="post-image">
      <input
        type="radio"
        name="post-item-shared"
        :id="'post_shared_' + post.id"
        class="hidden"
      />
      <a :href="post.path" class="block w-full">
        <div class="image-block aspect-square no-mask no-shadow">
          <img v-lazy="post.cover_image_src" :alt="post.title" class="image" />
        </div>
        <span class="image-mask aspect-square"></span>
      </a>
      <div
        v-if="parseFloat(post.price) === 0"
        class="w-10 h-4 text-center font-medium absolute free-tag top-0 left-2 pb-0.5 bg-red text-xs ml-2 mt-2"
      >
        {{ $t('post_item.free') }}
      </div>
      <div
        v-if="post.phrozen_verified"
        class="flex items-center justify-center w-10 h-4 text-center font-medium absolute pv-tag top-0 left-2 bg-red text-12px ml-2 mt-2"
      >
      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-0.5">
        <path d="M5.99941 9.57694C8.22979 9.57694 10.0379 7.76885 10.0379 5.53847C10.0379 3.30808 8.22979 1.5 5.99941 1.5C3.76902 1.5 1.96094 3.30808 1.96094 5.53847C1.96094 7.76885 3.76902 9.57694 5.99941 9.57694Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.9982 7.15363C6.89035 7.15363 7.61359 6.43039 7.61359 5.53824C7.61359 4.64609 6.89035 3.92285 5.9982 3.92285C5.10605 3.92285 4.38281 4.64609 4.38281 5.53824C4.38281 6.43039 5.10605 7.15363 5.9982 7.15363Z" fill="white"/>
        <path d="M8.15536 11.9996L6.00119 9.8457L3.84766 11.9996" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>

        PV
      </div>

      <div v-if="parseFloat(post.price) > 0"
           class="post-price-container items-center absolute bottom-0 ml-2">
        <span class="post-price">
          <Currency :price="post.price" />
        </span>
        <span
          v-if="parseFloat(post.price) < parseFloat(post.original_price)"
          class="post-original-price"
        >
          <Currency :price="post.original_price" />
        </span>
      </div>

      <div v-if="parseFloat(post.price) === 0"
           class="post-data-container items-center absolute bottom-0 left-0 mb-2 ml-2">
        <div v-if="parseFloat(post.views_count) >= 5" 
             class="post-view-count flex items-center pr-2">
          <div class="icon mr-1 w-4 h-4"></div>
            {{ formatNumber(post.views_count) }}
        </div>
        <div v-if="parseFloat(post.likes_count) >= 5" 
             class="post-likes-count flex items-center pr-2">
          <div class="icon mr-1 w-4 h-4"></div>
            {{ formatNumber(post.likes_count) }}
        </div>
        <div v-if="parseFloat(post.downloads_count) >= 5"
             class="post-downloads-count flex items-center pr-2">
          <div class="icon mr-1 w-4 h-4"></div>
            {{ formatNumber(post.downloads_count) }}
        </div>
      </div>

    </div>

    <div class="post-info p-2 py-3">
      <div class="post-title truncate" :title="post.title">
        <a :href="post.path" class="hover:underline">{{ post.title }}</a>
      </div>
      <a :href="post.creator.public_url" class="creator-info">
        <div class="w-full flex items-center">
          <div class="flex-shrink-0">
            <img
              v-lazy="post.creator.avatar_url"
              :alt="post.creator.brand_name"
              class="w-6 h-6 rounded-full"
            />
          </div>
          <div class="pl-2 flex-1 truncate">
            <span class="text-14px display-name">
              {{ post.creator.brand_name }}
            </span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from "vue";
  import { setupSharedLinks } from '../utils/postItemSharedLinks';
  import Currency from './Currency.vue';

  export default defineComponent({
    props: ['post'],
    methods: {
      labelClick(e) {
        let btn = document.getElementById(e.target.dataset.for)

        if (btn.checked) {
          btn.checked = false
        } else {
          btn.checked = true
          setupSharedLinks(e.target)
        }
      },
      formatNumber(value) {
        if (!value) return '0';
        value = Number(value);
        if (value < 1000) return value.toString();
        const result = (value / 1000).toFixed(1);
        return result + 'k';
      }
    },
    components: {
      Currency
    }
  })
</script>
