import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import CookieModal from '../../vue/pages/CookieModal.vue';

class CookieModalComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(CookieModal);
    this.app.use(I18NextVue, { i18next });

    this.app.mount(this.container);
  }
}

export default CookieModalComponent;
