<template>
  <div class="w-full flex flex-wrap">
     <div id="campaign-container" class="w-container w-full !h-auto">
        <div class="relative my-6 flex flex-col flex-wrap gap-4 lg:flex-row">
          <div>
            <div class="search-icon" @click="handleSearch"></div>
            <input 
              v-model="state.currentSearch" 
              type="search" 
              :placeholder="$t('models.model_search_form.collections')" 
              class="flex review-search-bar  w-full h-10 bg-grey-4 py-2 px-4 mr-2 rounded-3xl sm:w-[292px] border border-transparent focus:outline-none focus:border-[#2bdee9] placeholder:text-[16px]"
              @search="handleSearch">
          </div>
          <div class="flex flex-wrap gap-2">
            <span 
              v-for="(tag, index) in limitedPinCampaignTags" 
              :key="index" 
              class="flex rounded-[24px] px-4 py-1 max-h-8 items-center cursor-pointer text-14px"
              :class="{
                'bg-[#A3008E1A] border border-[#FFD4FA] hover:bg-[#A3008E4D]': index < 3,
                'bg-[#4B4B4B] hover:bg-grey-2': index >= 3,
              }"
                @click="state.currentSearch = tag; handleSearch()"
            >
              {{ tag }}
            </span>
          </div>
        </div>
       <div class="flex justify-between gap-x-2">
        <div class="text-18px">
         {{ $t('campaigns.index.all_collections')}}
        </div>
        <div class='comment-order-type relative pr-2'> 
            <div class="icon brightness-50"></div>
            <template v-for="type in orderTypes" :key="type.value">
              <button class="order-type-btn"
                      :class="{ 'active': state.orderType === type.value}"
                      @click="updateOrderType(type.value)"
                      >
                {{ $t(`posts.post_sorting.${type.label}`) }}
              </button>
              <span class='text-grey-3' v-if="!type.isLast">|</span>
            </template>
        </div>
      </div>

      <template v-if="items.length > 0">
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mt-6">
          <campaign-card
              v-for="(campaign, index) in items"
              :key="campaign.id"
              :campaign="campaign"
            />
        </div>
      </template>
      <template v-else-if="!state.initialLoading && !state.loading">
        <div class="w-full flex flex-col justify-center items-center text-center text-20px py-20">
            <img src="../../images/icons/search.svg" alt="search" class="w-12 h-12 mb-8"/>
          {{ $t('campaigns.index.not_found')}}
        </div> 
      </template>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, reactive, computed, onMounted, onBeforeUnmount } from "vue";
import debounce from 'lodash/debounce';

export default defineComponent({
  setup(props) {
    const state = reactive({
      loading: false,
      initialLoading: true,
      itemsCount: 9,
      currentSearch: '',
      orderType: '',
      campaigns: [],
      pin_campaign_tags: [],
      totalCount: 0,
      currentPage: 1,
      hasMore: true,
    });

    const orderTypes = computed(() => {
      const types = [
        { value: 'newest', label: 'newest' },
        { value: 'views', label: 'views' },
      ];

      types[types.length - 1].isLast = true;
      return types;
    });

    const items = computed(() => state.campaigns);

    const limitedPinCampaignTags = computed(() => {
      return state.pin_campaign_tags.slice(0, 8);
    });

    const resetState = () => {
      state.currentPage = 1;
      state.campaigns = [];
      state.hasMore = true;
      state.loading = false;
    };

    const getCampaigns = async () => {      
      const url = `/api/v1/campaigns`;
      
      const params = new URLSearchParams({
        page: state.currentPage,
        per_page: state.itemsCount,
        kw: state.currentSearch,
        order_type: state.orderType
      });

      try {
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          const data = await response.json();
          state.totalCount = data.total_entries;
          state.campaigns = [...state.campaigns, ...data.data];
          state.pin_campaign_tags = data.pin_campaign_tags
          state.hasMore = state.currentPage < data.total_pages;
          if (state.hasMore) {
            state.currentPage++;
          }
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      } finally {
        state.loading = false;
        state.initialLoading = false;
      }
    };

    const fetchItemData = async () => {
      if (!state.hasMore || state.loading) return;

      state.loading = true;
      await getCampaigns();
    };

    const updateOrderType = async (type) => {      
      if (state.orderType === type) {
        state.orderType = '';
      } else {
        state.orderType = type;
      }

      state.currentPage = 1;
      state.campaigns = []; 
      state.hasMore = true;
      await fetchItemData();
    };

    const handleSearch = async () => {
      resetState();
      await fetchItemData();
    };

    const handleScroll = debounce(() => {
      if (!state.hasMore || state.loading) return;
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      if (scrolledPercentage > 30) {
        fetchItemData();
      }
    }, 100);

    onMounted(async () => {      
      await fetchItemData();
      document.addEventListener('scroll', handleScroll, { passive: true });
    });

    onBeforeUnmount(() => {
      document.removeEventListener('scroll', handleScroll);
    });
    
    return {
      items,
      state,
      orderTypes,
      limitedPinCampaignTags,
      handleSearch,
      updateOrderType
    };
  }
});
</script>
