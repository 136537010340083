
<template>
  <div v-if="state.init" class='flex h-full items-center justify-center'>
    <div>
      {{ $t('cart.init') }}
    </div>
  </div>

  <div v-else-if="state.order !== null" class='flex flex-col smd:grid smd:grid-cols-[1fr_268px] md:grid-cols-[1fr_296px] grid-rows-[auto_1fr] grid-flow-col max-w-[1120px] smd:h-full mx-auto gap-x-1 lg:gap-x-4 smd:py-10 pb-4 sm:pb-6'>
    <!-- cart_item -->
    <div class="shopping-cart pr-3">
      <!-- campaign_banner -->
      <div v-if="campaignBanner && isCampaignActive" 
           class="campaign-banner py-2 " 
           :style=" {
              backgroundImage: `url(${campaignBanner.banner_bg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }">
        <div class="flex justify-center items-center lg:max-h-14">
          <img v-if="campaignBanner.banner_icon" 
              :src="campaignBanner.banner_icon" 
              alt="Banner Icon"
              class="sm:w-12 lg:w-10 ">
          <component :is="campaignBanner.campaign_link ? 'a' : 'div'"
                     :href="campaignBanner.campaign_link"
                     :target="campaignBanner.campaign_link ? '_blank' : null"
                     :class="['inline-block max-w-[85%]', { 'underline': campaignBanner.campaign_link }]">
            <div v-if="campaignBannerContent"
                 v-html="campaignBannerContent"
                 class="text-[14px] ml-4 lg:ml-6 mr-2">
            </div>
          </component>
        </div>        
      </div>

      <h1 class="pt-4 text-24px font-bold">
        {{ $t('cart.shopping_cart') }}
      </h1>
      <p class='text-14px text-[#8694a2] text-medium'>
        {{ $t('cart.you_have') }}
        <span class='text-primary text-16px font-bold'>
          {{ state.cart.variants.length }}
        </span>
        {{ $t('cart.items_in_your_shopping_cart') }}
      </p>

      <div class='continue-shopping-btn inline-flex items-center my-4 btn btn-secondary rounded-full text-14px'>
        <img src="../../images/icons/back-page.svg" class='h-4 w-4 inline-block' />
        {{ $t('cart.continue_shopping') }}
      </div>
      <div class="hidden lg:grid w-full px-2 text-grey-3 font-medium text-14px lg:grid-cols-[84px_84fr_54fr_18fr_38fr_80px] gap-x-3 lg:gap-x-4">
        <div>
          {{ $t('cart.item') }}
        </div>
        <div>&nbsp;</div>
        <div>
          {{ $t('cart.variant_name') }}
        </div>
        <div>&nbsp;</div>
        <div>
          {{ $t('cart.price') }}
        </div>
        <div>&nbsp;</div>
      </div>
    </div>

    <div class="relative mb-2 smd:m-0 min-h-full">
      <div class='smd:absolute w-full max-h-full overflow-y-auto smd:scrollbar smd:-mr-3 smd:pr-1'>
        <template v-for="(variant, idx) in state.cart.variants">
          <hr v-if="idx == 0" class='border-grey-3'>

          <div :id="`post-item-${variant.post.id}`" class="flex items-center post-widgets my-2 h-[100px] bg-grey-2 rounded-lg smd:justify-between">
            <div class="w-full px-2 grid grid-cols-[84px_1fr_60px] smd:grid-cols-[84px_1fr_48px] lg:grid-cols-[84px_1fr_80px] gap-x-3 lg:gap-x-4">
              <a :href="`/posts/${variant.post.id}`" class="relative">
                <img :src="variant.post.cover_image_src" class='h-[84px] w-[84px] rounded-lg inline-block max-w-none' />
                <div
                    v-if="parseFloat(variant.price) === 0"
                    class="w-10 h-4 text-center font-medium absolute free-tag pb-0.5 top-1 left-1 bg-red text-xs"
                  >
                    {{ $t('post_item.free') }}
                </div>
                <div
                    v-if="variant.phrozen_verified"
                    class="flex items-center justify-center w-10 h-4 text-center font-medium absolute pv-tag top-1 left-1 bg-red text-12px"
                  >
                  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-0.5">
                    <path d="M5.99941 9.57694C8.22979 9.57694 10.0379 7.76885 10.0379 5.53847C10.0379 3.30808 8.22979 1.5 5.99941 1.5C3.76902 1.5 1.96094 3.30808 1.96094 5.53847C1.96094 7.76885 3.76902 9.57694 5.99941 9.57694Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.9982 7.15363C6.89035 7.15363 7.61359 6.43039 7.61359 5.53824C7.61359 4.64609 6.89035 3.92285 5.9982 3.92285C5.10605 3.92285 4.38281 4.64609 4.38281 5.53824C4.38281 6.43039 5.10605 7.15363 5.9982 7.15363Z" fill="white"/>
                    <path d="M8.15536 11.9996L6.00119 9.8457L3.84766 11.9996" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                  </svg>
                    PV
                </div>
              </a>
              <div class="grid lg:grid-cols-[34fr_31fr_15fr] gap-y-2 gap-x-3 lg:gap-x-4">
                <div class="line-clamp-3 break-word text-14px font-medium leading-[20px] max-h-[20px]">
                  <a :href="`/posts/${variant.post.id}`" :title="variant.post.title">
                    {{ variant.post.title }}
                  </a>
                </div>
                <div class="line-clamp-3 break-word text-14px font-medium leading-[20px] max-h-[20px]">
                  {{ variant.name }}
                </div>
                <div class="flex flex-col font-medium">
                  <span class="text-16px font-black">
                    <Currency :price="variant.price" />
                  </span>
                  <span v-if='variant.price < variant.original_price' class="text-14px line-through text-gray-20 font-normal">
                    <Currency :price="variant.original_price" />
                  </span>
                </div>
              </div>
              <div class="flex justify-center items-center">
                <button class="destroy-post w-10 h-10 inline-block rounded-full !bg-[length:20px_20px] hover:bg-grey-3"
                        :disabled="state.btn_disabled"
                        @click.prevent='deleteVariant(variant)'>
                </button>
              </div>
            </div>
          </div>

          <hr class='border-grey-3'>
        </template>
      </div>
    </div>

    <!-- order_summary -->
    <div class="row-span-2">
      <div class="p-2 bg-grey-7 rounded-xl">
        <div class='m-2 mb-4 text-24px font-bold'>
          {{ $t('cart.order_summary') }}
        </div>

        <div class="py-2 px-4 bg-[#2E3134] rounded-8px">
          <!-- promotion -->
          <div id="cart-promotion" :key="state.in_used_promotion_items[0]?.code">
            <input id='discount-type-promotion' type='radio' name='discount-type' value='promotion' :checked="discount_state.collapse_type == 'promotion'">
            <div class="text-16px font-medium mb-1">
              {{ $t('cart.promotion') }}
            </div>
            <template v-if="state.in_used_promotion_items.length == 0">
              <form class="edit_cart">
                <div class="relative mb-2">
                  <div :class="discount_state.promotion_error == '' ? 'relative' : 'relative error'">
                    <input id='promotion-code-input' type="text" name='promotion_code' class='w-full default-input-klass'
                           :placeholder="$t('cart.enter_promotion')"
                           :value="discount_state.selected_promotion"
                           @keyup='typePromotionCode'>
                    <span class="error-msg"></span>
                  </div>
                  <div class='collapse-btn' @click="changeDiscountCollapseType('promotion')"></div>
                </div>

                <div v-if="discount_state.promotion_error != ''" class="error-msg text-12px text-danger -mt-1 mb-1">
                  {{ discount_state.promotion_error }}
                </div>

                <div class="items-content overflow-y-auto smd:scrollbar smd:-mr-3 smd:pr-1">
                  <div class="flex flex-col gap-y-2">
                    <template v-for="promotion in state.available_promotions">
                      <div :class="'discount-item p-2 border border-grey-1 rounded-8px ' + selectedPromotionStyle(promotion.code)"
                           @click="selectPromotion(promotion.code)">
                        <div class="item-code text-14px break-all font-medium">
                          {{ promotion.code }}
                        </div>
                        <div class="item-price text-12px font-medium mb-4">
                          <Currency :price="promotion.discount_price" />
                        </div>
                        <div class="item-expires text-12px text-grey-5">
                          {{ $t('cart.discount_item.expires') }}
                          <span class="ml-2 font-medium">
                            <IsoToUTC :isoDate="promotion.end_at" />
                          </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <button class="w-full btn btn-sm btn-primary"
                  :disabled="discount_state.selected_promotion == '' || state.btn_disabled"
                  @click.prevent="submitPromotion">
                  {{ $t('cart.discount_item.apply') }}
                </button>
              </form>
            </template>
            <template v-else>
              <div v-for="promotion_item in state.in_used_promotion_items" class="flex items-center text-14px font-medium text-gray-10">
                <div class="flex-1 truncate" :title="promotion_item.source.code">
                  {{ promotion_item.source.code }}
                </div>
                <div class="mx-1">
                  <Currency :price="promotion_item.price" />
                </div>
                <button class='cursor-pointer'
                  :disabled="state.btn_disabled"
                  @click='deletePromotion(promotion_item.source.code)'>
                  <img src="../../images/icons/cancel-select-coupon.svg" class='h-6 w-6 inline-block mb-[2px]' />
                </button>
              </div>
            </template>
          </div>
          <!-- coupon -->
          <div id="cart-coupon" class='mt-6' :key="state.in_used_coupon_items.map(obj => obj.source.code).join(', ')">
            <input id='discount-type-coupon' type='radio' name='discount-type' value='coupon' :checked="discount_state.collapse_type == 'coupon'">
            <div class="text-16px font-medium mb-2">
              {{ $t('cart.coupon') }}
            </div>
            <div id='selected-coupon' class="mb-2">
              <div v-for="coupon_item in state.in_used_coupon_items" class="flex items-center text-14px font-medium text-gray-10">
                <div class="flex-1 truncate" :title="coupon_item.source.code">
                  {{ coupon_item.source.code }}
                </div>
                <div class="mx-1">
                  <Currency :price="coupon_item.price" />
                </div>
                <button class='cursor-pointer'
                  :disabled="state.btn_disabled"
                  @click='deleteCoupon(coupon_item.source.code)'>
                  <img src="../../images/icons/cancel-select-coupon.svg" class='h-6 w-6 inline-block mb-[2px]' />
                </button>
              </div>
            </div>

            <form class="edit_cart">
              <div class="items-content overflow-y-auto smd:scrollbar smd:-mr-3 smd:pr-1">
                <div class="flex flex-col gap-y-2">
                  <template v-for="coupon in state.available_coupons">
                    <div :class="'discount-item p-2 border border-grey-1 rounded-8px ' + selectedCouponStyle(coupon.code)"
                         @click="selectCoupon(coupon.code)">
                      <div class="item-code text-14px break-all font-medium">
                        {{ coupon.code }}
                      </div>
                      <div class="item-price text-12px font-medium mb-4">
                        <Currency :price="coupon.discount_price" />
                      </div>
                      <div class="item-expires text-12px text-grey-5">
                        {{ $t('cart.discount_item.expires') }}
                        <span class="ml-2 font-medium">
                          <IsoToUTC :isoDate="coupon.end_at" />
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div v-if="discount_state.coupon_error != ''" class="error-msg text-12px text-danger -mt-1 mb-1">
                {{ discount_state.coupon_error }}
              </div>

              <div v-if="discount_state.collapse_type == 'coupon'" class="btns flex gap-2">
                <div class="flex-1 btn btn-sm btn-secondary" @click="changeDiscountCollapseType('coupon')">
                  {{ $t('cart.discount_item.cancel') }}
                </div>
                <button class="flex-1 btn btn-sm btn-primary"
                  :disabled="discount_state.selected_coupons.length == 0 || state.btn_disabled"
                  @click.prevent="submitCoupon">
                  {{ $t('cart.discount_item.apply') }}
                </button>
              </div>
              <div v-else-if="state.available_coupons.length == 0" class="coupon-collaspe-btn btn btn-sm btn-primary disabled">
                {{ $t('cart.discount_item.choose') }}
              </div>
              <div v-else class="coupon-collaspe-btn btn btn-sm btn-primary" @click="changeDiscountCollapseType('coupon')">
                {{ $t('cart.discount_item.choose') }}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="mt-4 p-4 bg-grey-7 rounded-xl">
        <div class='flex flex-col gap-y-1 text-16px font-medium'>
          <div id='cart-subtotal' class='flex justify-between'>
            <div class="text">
              {{ $t('cart.subtotal') }}
            </div>
            <div class="price">
              <Currency :price="state.order.order_price" />
            </div>
          </div>
          <div id='cart-discount' class='flex justify-between'>
            <div class="text">
              {{ $t('cart.discount') }}
            </div>
            <div class="price">
              <Currency :price="state.order.discount_price" />
            </div>
          </div>
        </div>
        <hr class='border-grey-3 my-4'>
        <div class='flex flex-col gap-y-4'>
          <div id='cart-total-price' class='flex justify-between text-20px font-medium'>
            <div class="text">
              {{ $t('cart.total_price') }}
            </div>
            <div class="price">
              <Currency :price="state.order.final_price" />
            </div>
          </div>

          <form @submit.prevent="checkout">
            <input v-if="state.btn_disabled" type="submit" class="w-full h-auto text-16px btn stripe_gw_btn hover:bg-[#7e7aff]"
                   :value="$t('cart.pay_with_gateway', { gateway: state.cart.gateway_provider })" disabled>
            <input v-else type="submit" class="w-full h-auto text-16px btn stripe_gw_btn hover:bg-[#7e7aff]"
                   :value="$t('cart.pay_with_gateway', { gateway: state.cart.gateway_provider })"
                   :data-disable-with="$t('cart.redirect_to_gateway', { gateway: state.cart.gateway_provider })">
          </form>

          <div class="flex">
            <img src="../../images/alert-warning.svg" class='h-6 w-6 mt-1 mr-2' />
            <div class="text-12px text-[#FFCC6C]">
              {{ $t('cart.purchase_reminder') }}
              <a class="text-[#FFCC6C] underline" href="/term_service">
                {{ $t("home.footer.terms_of_service") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="flex flex-col justify-center items-center h-full xs:py-10 smd:py-30  xs:mx-2 smd:mx-20">
    <p class='font-medium xs:text-24px sm:text-32px'>
      {{ $t('cart.empty') }}
    </p>
    <p class='xs:w-[234px] sm:w-auto text-center mt-1.5 font-normal text-gray-10 text-16px'>
      {{ $t('cart.empty_content') }}
    </p>
    <img src="../../images/cart-empty.svg" class='inline-block max-w-[84px] max-h-[84px] mb-8 mt-6' />
    <div class='continue-shopping-btn inline-flex items-center btn btn-secondary rounded-full text-14px'>
      <img src="../../images/icons/back-page.svg" class='h-4 w-4 inline-block' />
      {{ $t('cart.continue_shopping') }}
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, reactive, computed, onMounted, onUpdated, nextTick} from "vue";
import debounce from 'lodash/debounce';

export default defineComponent({
  setup(props) {
    const currentUser = ref(null);
    const state = reactive({
      init: true,
      loading: false,
      btn_disabled: false,
      cart: null,
      order: null,
      available_promotions: [],
      available_coupons: [],
      in_used_promotion_items: [],
      in_used_coupon_items: []
    });

    const discount_state = reactive({
      collapse_type: 'promotion',
      selected_promotion: '',
      promotion_error: '',
      selected_coupons: [],
      coupon_error: ''
    });

    const campaignBanner = ref(null);

    const isCampaignActive = computed(() => {
      if (!campaignBanner.value) return false;
      
      const now = new Date();
      const startDate = new Date(campaignBanner.value.start_at);
      const endDate = new Date(campaignBanner.value.end_at);
      
      return now >= startDate && now <= endDate;
    });

    const campaignBannerContent = computed(() => {
      return campaignBanner.value?.content || '';
    });

    const fetchCurrentUser = async () => {
      try {
        const response = await fetch('/api/v1/profile/current_user', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          currentUser.value = await response.json();
        } else {
          throw new Error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchCartData = async () => {
      if (state.loading) return;

      state.loading = true;

      try {
        const response = await fetch('/api/v1/cart/current_cart');

        if (response.ok) {
          const data = await response.json();

          // 初始 in_used_discount
          state.in_used_promotion_items = []
          state.in_used_coupon_items = []

          state.cart = data.cart;
          state.order = data.order;
          state.available_promotions = data.available_promotions;
          state.available_coupons = data.available_coupons;

          if (state.init && state.available_promotions.length > 0) {
            discount_state.selected_promotion = state.available_promotions[0].code
          }

          data.order?.line_items.forEach(line_item => {
            if (line_item.source_type == 'Promotion') {
              state.in_used_promotion_items.push(line_item)
            } else if (line_item.source_type == 'Coupon') {
              state.in_used_coupon_items.push(line_item)
            }
          });
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
      } finally {
        state.init = false;
        state.loading = false;
      }
    };

    const fetchCampaignBanner = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const locale = urlParams.get('locale') || '';
        const response = await fetch(`/api/v1/cart/campaign_banner?locale=${locale}`);

        if (response.ok) {
          const data = await response.json();
          campaignBanner.value = data.banner;
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
      } 
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const deleteVariant = async (variant) => {
      if (state.btn_disabled) return;

      state.btn_disabled = true;

      try {
        // tracking
        const eventData = formatTrackingData(variant)
        trackingEvent.remove_from_cart(eventData)

        const response = await fetch(`/api/v1/cart/variants/${variant.id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          await fetchCartData()

          // update cart icon number
          let evt = new CustomEvent('addToCart', { 'detail': { 'cart_item_count': state.cart.variants.length } });
          document.dispatchEvent(evt);
        } else {
          throw new Error('Failed to delete variant');
        }
      } catch (error) {
        console.error('Error deleting variant:', error);
      } finally {
        state.btn_disabled = false
      }
    };

    const changeDiscountCollapseType = (value) => {
      if (discount_state.collapse_type == value) {
        discount_state.collapse_type = ''
        discount_state.selected_promotion = ''
        discount_state.selected_coupons = []
        discount_state.promotion_error = ''
        discount_state.coupon_error = ''
      } else {
        discount_state.collapse_type = value
      }
    };

    const typePromotionCode = (event) => {
      discount_state.selected_promotion = event.target.value
      discount_state.promotion_error = ''
    };

    const selectPromotion = (code) => {
      discount_state.selected_promotion = code
      discount_state.promotion_error = ''
    };

    const selectedPromotionStyle = (code) => {
      if (discount_state.selected_promotion == code) {
        return 'selected';
      } else {
        return '';
      }
    };

    const submitPromotion = async () => {
      if (state.btn_disabled) return;

      state.btn_disabled = true;

      try {
        const formData = new FormData()
        formData.append('code', discount_state.selected_promotion)

        const response = await fetch('/api/v1/cart/promotions', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          await fetchCartData()
        } else {
          const errorData = await response.json();
          discount_state.promotion_error = errorData.error
        }
      } catch (error) {
        console.error('Error submiting promotion:', error);
      } finally {
        state.btn_disabled = false
      }
    };

    const deletePromotion = async (code) => {
      if (state.btn_disabled) return;

      state.btn_disabled = true;

      try {
        const response = await fetch(`/api/v1/cart/promotions/${code}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          await fetchCartData()
        } else {
          discount_state.promotion_error = response.json().error
        }
      } catch (error) {
        console.error('Error deleting promotion:', error);
      } finally {
        state.btn_disabled = false
      }
    };

    const selectCoupon = (code) => {
      let coupons = discount_state.selected_coupons

      if (coupons.indexOf(code) > -1) {
        discount_state.selected_coupons = coupons.filter(function(e) { return e !== code })
      } else {
        discount_state.selected_coupons.push(code)
      }

      discount_state.coupon_error = ''
    };

    const selectedCouponStyle = (code) => {
      if (discount_state.selected_coupons.indexOf(code) > -1) {
        return 'selected';
      } else {
        return '';
      }
    };

    const submitCoupon = async () => {
      if (state.btn_disabled) return;

      state.btn_disabled = true;

      try {
        const formData = new FormData()
        discount_state.selected_coupons.forEach(function(code) {
          formData.append('codes[]', code)
        })

        const response = await fetch('/api/v1/cart/coupons', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          await fetchCartData()
        } else {
          const errorData = await response.json();
          discount_state.coupon_error = errorData.error
        }
      } catch (error) {
        console.error('Error submiting coupon:', error);
      } finally {
        state.btn_disabled = false
      }
    };

    const deleteCoupon = async (code) => {
      if (state.btn_disabled) return;

      state.btn_disabled = true;

      try {
        const response = await fetch(`/api/v1/cart/coupons/${code}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          await fetchCartData()
        } else {
          throw new Error('Failed to delete coupon');
        }
      } catch (error) {
        console.error('Error deleting coupon:', error);
      } finally {
        state.btn_disabled = false
      }
    };

    const checkout = () => {
      state.btn_disabled = true;

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = '/cart/checkout';
      form.style.display = 'none';

      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'authenticity_token';
      input.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      form.appendChild(input);

      document.body.appendChild(form);
      form.submit();
    };

    const handleContinueShopping = () => {
      const currentHost = window.location.host;
      const referrer = document.referrer;

      if (referrer && referrer !== '' && referrer.includes(currentHost)) {
        window.location.href = referrer;
      } else {
        window.location.href = '/posts';
      }
    };

    const formatTrackingData = (variant) => {
      return {
        'id': variant.id,
        'title': `${variant.post.title}_${variant.name}`,
        'price': variant.price,
        'original_price': variant.original_price,
        'designer': variant.post.creator.brand_name
      }
    }

    onMounted(async () => {
      try {
        await fetchCurrentUser();

        if (currentUser.value) {
          await fetchCartData();
          await fetchCampaignBanner();
        } else {
          console.error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error in onMounted:', error);
      } finally {
        // tracking
        if (state.order) {
          const eventData = {
            finalPrice: state.order.final_price,
            items: []
          }

          state.cart.variants.forEach(variant => {
            const variantData = formatTrackingData(variant)
            eventData.items.push(variantData)
          })
          trackingEvent.view_cart(eventData)
        }
      }
    });

    onUpdated(async () => {
      document.querySelectorAll('.continue-shopping-btn').forEach(button => {
        button.addEventListener('click', handleContinueShopping);
      });
    });

    return {
      currentUser,
      state,
      campaignBanner,
      campaignBannerContent,
      discount_state,
      isCampaignActive,
      deleteVariant,
      changeDiscountCollapseType,
      typePromotionCode,
      selectPromotion,
      selectedPromotionStyle,
      submitPromotion,
      deletePromotion,
      selectCoupon,
      selectedCouponStyle,
      submitCoupon,
      deleteCoupon,
      checkout,
      formatDate
    };
  }
});
</script>
