import { Controller } from "stimulus"
import Swiper, { Mousewheel } from 'swiper';

// 用在首頁的image slide
export default class extends Controller {
  static targets = ['swiper']

  connect() {
    this.swiper = new Swiper("#home-intro", {
      modules: [ Mousewheel ],
      direction: "vertical",
      slidesPerView: 1,
      spaceBetween: 0,
      mousewheel: true,
      speed: 700,
      enabled: false,
      mousewheel: {
        thresholdDelta: 2
      },
      breakpoints: {
        780: {
          enabled: true
        }
      },
      on: {
        init: function(sw){
          const introSession = window.location.hash.match(/intro-\d{1}/)
          if (introSession) {
            const idx = introSession[0].match(/\d/)[0]
            sw.slideTo(idx - 1, 1, false)
          } else {
            window.location.hash = `intro-1`;
          }
        },
        slideChange: function(sw){
          const currentIndex = this.activeIndex + 1;
          window.location.hash = `intro-${currentIndex}`;
        }
      }
    });
  }
  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
  click() {
    this.swiper.slideTo(1, 700, true)
  }
}
