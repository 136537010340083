import TomSelect from 'tom-select'

const SELECTOR = '[data-uniform-select]'

class UniformSelect {
  constructor(dom) {
    this.dom = dom
    this.instance = null

    this.init()
  }

  init() {
    if (this.dom.dataset.complete === '1') {
      return
    }

    var opts = {}

    if (this.dom.multiple) {
      opts = {
        plugins: {
          remove_button:{
            title: 'Remove this item',
          }
        },
        create: false,
        maxItems: this.dom.options.length,
        closeAfterSelect: true
      }
    } else if ((this.dom.dataset.creatable)) {
      opts = {
        create: true
      }
    } else {
      opts = {
        create: false,
        controlInput: null,
        maxOptions: null,
      }
    }

    if (this.dom.dataset.controllerInput) {
      delete opts['controlInput'];
    }

    if (this.dom.dataset['allowEmpty']) {
      opts['allowEmptyOption'] = true
    }

    this.instance = new TomSelect(this.dom, opts);

    if(this.dom.multiple){
      setTimeout(()=>{
        let changeElement = document.querySelector("div.ts-control.default-input-klass.plugin-remove_button")
        if(changeElement){
          this.instance.on('dropdown_open',()=>{
            changeElement.style.borderColor = 'var(--color-blue-10)';
          })
          this.instance.on('dropdown_close',()=>{
            changeElement.style.borderColor = '#fff';
          })
        }
      },200)
    }
    this.dom.dataset['complete'] = '1'
  }

  destroy() {
    this.dom.dataset['complete'] = null
    this.instance.destroy()
  }
}

let instances = []

export const initUniformSelect = () => {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    if (dom.dataset.complete !== '1') {
      instances.push(new UniformSelect(dom))
    }
  })
}

export const destroyUniformSelect = () => {
  instances.forEach((instance) => {
    instance.destroy()
  })
}
