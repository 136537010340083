import { createApp, reactive } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import IndexView from './layouts/profile_layouts/profile_page_designer_collection/Index.vue';
import NewView from './layouts/profile_layouts/profile_page_designer_collection/New.vue';
import ShowView from './layouts/profile_layouts/profile_page_designer_collection/Show.vue';
import EditView from './layouts/profile_layouts/profile_page_designer_collection/Edit.vue';

import BaseCollectionForm from './layouts/profile_layouts/profile_page_designer_collection/BaseCollectionForm.vue';
import DesignerCollectionItem from "./components/DesignerCollectionItem.vue";
import SortedModelItem from "./components/SortedModelItem.vue";
import PostItem from "./components/PostItem.vue";

class ProfilePageDesignerCollectionComponent {
  constructor({ container, props = {} }) {
    this.app = null;
    this.container = container;
    this.props = props;
  }

  // 取得對應的 vue component
  getViewComponent() {
    const views = {
      'index': IndexView,
      'new': NewView,
      'edit': EditView,
      'show': ShowView,
    };
    return views[this.props.view];
  }

  init() {
    const ViewComponent = this.getViewComponent();
    if (!ViewComponent) {
      console.error(`View '${this.view}' not found`);
      return;
    }

    this.app = createApp(ViewComponent, this.props);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('sorted-model-item', SortedModelItem);
    this.app.component('base-collection-form', BaseCollectionForm);
    this.app.component('designer-collection-item', DesignerCollectionItem);
    this.app.component('post-item', PostItem);
    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfilePageDesignerCollectionComponent;
