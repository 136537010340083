<template>
  <div v-if="isVisible" 
    class="cookie-modal-container fixed bottom-[5.5rem] sm:bottom-12 left-0 w-full z-10 flex justify-center items-center"
  >
    <div class="cookie-modal flex flex-col md:flex-row justify-center md:w-[80%] max-w-[1200px] p-6 rounded-2xl bg-gray-45 text-white">
      <div class="cookie-container flex flex-wrap w-full items-center gap-y-6 md:gap-x-6">
        <div class="content-group flex flex-col flex-[1_0_100%] md:flex-[1_0_60%]">
          <h1 class="title mb-2 text-left font-bold leading-5 text-orange-10">{{ $t('shared.cookie_modal.title') }}</h1>
          <div v-html="$t('shared.cookie_modal.content')" class="content text-sm text-left leading-6"></div>
        </div>
        <div class="button-group flex flex-wrap flex-[1_0_100%] md:flex-[1_0_30%]">
          <button class="btn accept" @click="acceptCookies">
            {{ $t('shared.cookie_modal.accept_btn') }}
          </button>
          <button class="btn reject" @click="necessaryCookies">
            {{ $t('shared.cookie_modal.necessary_btn') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "CookieModal",
  data() {
    return {
      isVisible: false, // 是否顯示彈窗
    };
  },
  mounted() {
    // 檢查是否已經存儲用戶選擇
    const consent = JSON.parse(localStorage.getItem("cookiePolicy"));
    const testEnv = window.global_values['ENV'];
    if (testEnv != 'test' && !consent) {
      setTimeout(() => {
        this.isVisible = true;
      }, 5000);
    }
  },
  methods: {
    // cookie 政策 all | essential
    acceptCookies() {
      localStorage.setItem("cookiePolicy", JSON.stringify({
        type: "all",
        timestamp: new Date().getTime()
      }));
      
      // in _tracking page
      window.tracking_script();

      window.trackingEvent.set_allow_consent();

      this.isVisible = false;
    },
    necessaryCookies() {
      localStorage.setItem("cookiePolicy", JSON.stringify({
        type: "essential",
        timestamp: new Date().getTime()
      }));

      window.trackingEvent.set_deny_consent();

      this.isVisible = false;
    },
  },
});
</script>

