<template>
  <div :id = "`comment-${comment.id}`"
       class = 'comment-item flex w-full mb-4'>
    <img v-lazy="comment.profile.avatar_url" :alt="comment.profile.username"
         class='rounded-full mt-2'
         :class="comment.parent_id ? 'w-6 h-6' : 'w-8 h-8'">

    <div class='comment-content ml-4 flex-1 w-full'>
      <div class="grid grid-1fr-auto">
        <div class='w-full'>
          <div class="grid grid-auto-1fr">
            <div class="grid grid-1fr-auto-auto gap-x-2 mr-2 items-center">
              <a :href="comment.profile.public_url" class='flex gap-1 items-center truncate'>
                <span v-if="comment.profile.is_designer" class='text-12px font-medium'>
                  {{ comment.profile.brand_name }}
                </span>
                <span v-else="comment.profile.display_name" class='text-12px font-medium'>
                  {{ comment.profile.display_name }}
                </span>
                <span class='text-12px text-grey-3'>
                  @{{ comment.profile.username }}
                </span>
              </a>
              <span v-if='comment.profile.id == state.post_profile.id' class='rounded-full px-2 py-0 bg-primary text-12px'>
                {{ $t('comment_form.creator') }}
              </span>
              <span v-else-if="comment.profile.is_admin_user" class='rounded-full px-2 py-0 bg-primary text-12px'>
                {{ $t('comment_form.admin') }}
              </span>
              <span v-if='comment.parent_id' class='created_at text-12px text-grey-5'>
                {{ comment.created_at }}
              </span>
            </div>
          </div>
          <template v-if='!comment.parent_id'>
            <div class='created_at text-12px text-grey-5'>
              {{ comment.created_at }}
            </div>
            <div v-if='comment.stars > 0' class='review-rating flex items-center my-2'>
              <template v-for='rating in [1,2,3,4,5]' :key="rating">
                <span class='icon'
                      :class="comment.stars >= rating ? 'star' : 'star-empty'">
                </span>
              </template>
            </div>
          </template>
          <div class='content text-14px my-2' v-html="commentContent.state == 'original' ? commentContent.original : commentContent.translated">
          </div>
          <button v-if="commentContent.state == 'original'" class="mb-2 text-grey-5 text-14px underline" @click="handleTranslate">
            {{ $t('comment_form.translate_btn') }} 
          </button>
          <button v-else class="mb-2 text-grey-5 text-14px underline" @click="handleTranslate">
            {{ $t('comment_form.original_btn') }} 
          </button>
        </div>

        <div v-if='state.curr_profile' class='comment-more relative'>
          <input :id="`comment-${comment.id}-more`" type='radio' name='comment-more' class='hidden'>
          <label :for="`comment-${comment.id}-more`" class='cursor-pointer' @click.prevent="moreBtnClick(comment)">
            <span class='icon more-icon'></span>
          </label>

          <div class='more-content absolute right-0'>
            <template v-if='state.curr_profile && state.curr_profile.id == comment.profile.id'>
              <button class='w-full flex items-center' @click.prevent='editComment(comment)'>
                <span class='icon edit-icon mr-2'></span>
                <span class='min-w-[32px]'>{{ $t('comment_form.edit') }}</span>
              </button>
              <button class='w-full flex items-center' @click.prevent='deleteComment(comment)'>
                <span class='icon delete-icon mr-2'></span>
                <span class='min-w-[32px]'>{{ $t('comment_form.delete') }}</span>
              </button>
            </template>
            <template v-else-if='state.curr_profile.id != comment.profile.id'>
              <button class='flex items-center' @click.prevent='reportComment(comment)'>
                <span class='icon report-icon mr-2'></span>
                <span class='min-w-[32px]'>{{ $t('comment_form.report') }}</span>
              </button>
            </template>
          </div>
        </div>
      </div>

      <div v-if='comment.images.length > 0' class='flex mb-2 flex-wrap gap-2'>
        <template v-for="image in comment.images" :key="image.id">
          <div v-lazy:background-image="image.x1_url"
               class='inline-block image rounded-8px cursor-pointer'
               @click.prevent='openImageSlide(comment, image)'>
          </div>
        </template>
      </div>

      <div class='post_likes flex items-center'>
        <span :id="`comment-like-${comment.id}-btn`"
              ref="likeBtn"
              class='icon like w-5 h-5'
              :class="comment.liked ? 'selected' : ''"
              @click='likeBtnClick(comment)'>
        </span>
        <span ref="likeCount" class='text-12px text-grey-5 ml-2 mr-4'>
          {{ comment.like_count || '' }}
        </span>
        <span ref="unlikeBtn"
              class='icon unlike w-5 h-5'
              :class="comment.unliked ? 'selected' : ''"
              @click='unlikeBtnClick(comment)'>
        </span>
        <span class='ml-4 text-12px cursor-pointer cancel-btn'
              @click='replyBtnClick(comment)'>
          {{ $t('comment_form.reply') }}
        </span>
      </div>

      <div v-if='!comment.parent_id && comment.replies.length > 0' class='comment_replies my-2'>
        <input type='checkbox' 
               :id="`comment-${comment.id}-replies`"
               class='hidden'
               :checked="collapseReplies(comment)">

        <label :for="`comment-${comment.id}-replies`" class='flex items-center cursor-pointer'>
          <span class='icon arrow-icon w-6 h-6 mr-2'></span>
          <span class='text-primary text-14px'>
            <span class='text-primary font-bold'>
              {{comment.replies.length}}
            </span>
            {{ $t('comment_form.replies') }}
          </span>
        </label>

        <div class='comment_replies_content -mb-4'>
          <post-comment v-for="reply in comment.replies"
            :comment="reply"
            :commentState='commentState'
            :key="reply.id + reply.updated_at"
          ></post-comment>
        </div>
      </div>

      <comment-reply-form v-if='state.curr_profile && commentState.reply_comment_id == comment.id'
                          :key="commentState.reply_username"
                          :comment="comment"
                          :commentState='commentState'
                          :fetchCommentItemData='fetchCommentItemData'>
      </comment-reply-form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { state, reportState, slideState } from "../stores/postState";
import i18next from "i18next";

export default defineComponent({
  props: ['comment', 'fetchCommentItemData', 'commentState'],
  data() {
    return {
      // 用於顯示翻譯後的內容
      commentContent: {
        original: '',
        translated: '',
        state: 'original'
      },

      state,
      reportState,
      slideState,
    };
  },
  mounted() {
    this.commentContent = {
      original: this.comment.sanitized_content,
      translated: '',
      state: 'original'
    }
  },
  methods: {
    collapseReplies(comment) {
      // 這邊會判斷是否需要展開 replies
      // 1. replies 數 < 3
      // 2. 回覆 comment
      return comment.replies.length < 3 || this.commentState.reply_comment_id == comment.id
    },
    moreBtnClick(comment) {
      const btn = document.getElementById(`comment-${comment.id}-more`)

      if (btn.checked){
        btn.checked = false
      } else {
        btn.checked = true
      }
    },
    likeBtnClick(comment) {
      this.likeComment(comment)
    },
    unlikeBtnClick(comment) {
      this.unlikeComment(comment)
    },
    openImageSlide(comment, image) {
      this.slideState.fetchPath = `/comments/${comment.id}/images`
      this.slideState.openSlide = true
      this.slideState.images = []
      this.slideState.startImageId = image.id
    },
    replyBtnClick(comment) {
      const comment_id = comment.parent_id || comment.id
      const username = comment.profile.username

      if (this.commentState.reply_username != username || this.commentState.reply_comment_id != comment_id) {
        this.commentState.reply_comment_id = comment_id
        this.commentState.reply_username = username
      } else {
        this.commentState.reply_comment_id = ''
        this.commentState.reply_username = ''
      }
    },
    editComment(comment) {
      this.fetchCommentItem(comment)

      const targetComment = document.getElementById('post-comment-form')
      if (targetComment) {
        targetComment.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    reportComment(comment) {
      document.getElementById(`comment-${comment.id}-more`).checked = false

      this.reportState.openModal = true
      this.reportState.targetId = comment.id
      this.reportState.targetType = 'comment'
      this.reportState.reportKey = null
      this.reportState.reportReason = null
    },
    async likeComment(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }
      try {
        let likeCount
        const likeIcon = this.$refs.likeBtn

        if (likeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/comments/' + comment.id + '/likes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            likeIcon.classList.remove('selected')
            likeCount = data['like_count']

            if (likeCount == 0) {
              likeCount = ''
            }
          }
        } else {
          const requestUrl = '/api/v1/comments/' + comment.id + '/likes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const data = await response.json();
            likeCount = data['like_count']

            const unlikeIcon = this.$refs.unlikeBtn
            unlikeIcon.classList.remove('selected')
            likeIcon.classList.add('selected')
          }
        }

        if (likeCount != undefined) {
          likeIcon.nextSibling.innerHTML = likeCount
        }
      } catch (error) {
        console.log(error)
      }
    },
    async unlikeComment(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      try {
        const unlikeIcon = this.$refs.unlikeBtn

        if (unlikeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/comments/' + comment.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            unlikeIcon.classList.remove('selected')
          }
        } else {
          const requestUrl = '/api/v1/comments/' + comment.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const likeIcon = this.$refs.likeBtn
            const likeCountRef = this.$refs.likeCount

            if (likeIcon.classList.contains('selected')) {
              likeIcon.classList.remove('selected')

              let likeCount = parseInt(likeCountRef.innerHTML)
              likeCount -= 1

              if (likeCount == 0) {
                likeCount = ''
              }

              likeCountRef.innerHTML = likeCount
            }

            unlikeIcon.classList.add('selected')
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCommentItem(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      if (this.state.curr_profile.id != comment.profile.id){
        return
      }

      try {
        const api_path = '/api/v1/comments/' + comment.id
        const response = await fetch(api_path);
        if (response.ok) {
          const data = await response.json();
          const itemData = data['data']

          this.commentState.edit_target = itemData
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteComment(comment) {
      if (!confirm("Are you sure?")){
        return
      }

      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      if (this.state.curr_profile.id != comment.profile.id){
        return
      }

      try {
        const api_path = '/api/v1/comments/' + comment.id
        const response = await fetch(api_path, {method: 'delete'});

        if (response.ok) {
          this.fetchCommentItemData()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleTranslate() {
      // content state
      if (this.commentContent.state == 'original') {
        this.commentContent.state = 'translated'
      } else {
        this.commentContent.state = 'original'
      }

      // 有翻譯過就不再翻譯
      if (this.commentContent.translated != '') return;
      
      const browserLocale = navigator.language;
      try {
        const api_path = '/api/v1/translates'
        const response = await fetch(api_path, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            target_locale: browserLocale,
            content: this.commentContent.original
          })
        });
        
        if (response.ok) {
          const data = await response.json();
          this.commentContent.translated = data['translate']
        }
      } catch (error) {
        console.error('translate', error)
      }
    }
  }
});
</script>
../stores/postState